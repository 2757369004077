import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../svg/SvgIcon';
import TrackableLink from '../TrackableLink';

function FeedHeading(props) {
  const { heading, noSmallMargins = false, href, eventInfo } = props;

  if (!href) {
    return (
      <h2 className={`feed__heading ${noSmallMargins ? 'xs-ml0 md-ml1' : ''} `}>
        {heading}
      </h2>
    );
  }

  return (
    <>
      <TrackableLink
        className="xs-flex-row xs-hide md-block"
        linkTracking={{...eventInfo, item_type: 'text'}}
        url={href}
      >
        <h2 className="feed__heading feed__heading--with-link">
          {heading}
          <SvgIcon
            className="arrow md-ml1"
            width="11"
            height="15"
            id="caret-right"
            viewBox="0 0 512 512"
          />
        </h2>
      </TrackableLink>
      <h2 className="feed__heading extra-bold md-hide">{heading}</h2>
    </>
  );
}

FeedHeading.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default FeedHeading;
