import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import FeedHeading from '../FeedHeading';
import FeedItem from '../FeedItem';
import TrackableLink from '../TrackableLink';
function Feed(props) {
  const {
    className,
    items,
    heading,
    href = '',
    columns = 2,
    hasLargeLabelDesktop = false,
    hasLargeLabelMobile = false,
    hasRightText = false,
    hasSmallText = false,
    hasWideImage = false,
    isScrollableMobile = false,
    mediaSizes,
    placeholder = { enabled: false, count: 0 },
    preloadImageCount = 0,
    displayBrand = true,
    // CET
    dataSourceAlgorithm,
    dataSourceAlgorithmVersion,
    dataSourceName,
    positionInUnit = 0,
    subunitName = '',
    unitName,
    unitType,
  } = props;

  const sharedProps = {
    columns,
    hasLargeLabelDesktop,
    hasLargeLabelMobile,
    hasRightText,
    hasSmallText,
    hasWideImage,
    isScrollableMobile,
    mediaSizes,
    // CET
    dataSourceAlgorithm,
    dataSourceAlgorithmVersion,
    dataSourceName,
    subunitName,
    unitName,
    unitType,
    displayBrand,
  };
  const itemName = href.slice(1).replace('/', '_');

  const eventInfo = {
    item_name: itemName,
    position_in_subunit: 0,
    position_in_unit: positionInUnit,
    subunit_name: subunitName,
    subunit_type: 'package',
    target_content_id: itemName,
    target_content_type: 'feed',
    unit_name: unitName,
    unit_type: unitType,
  };

  let feed = null;
  if (placeholder && placeholder.enabled) {
    feed = [...Array(placeholder.count).keys()].map((index) => (
      <FeedItem
        key={`${index}`}
        item={{}}
        isPlaceholder
        {...sharedProps}
      />
    ));
  } else if (items && Array.isArray(items)) {
    feed = (
      <>
        {items.map((item, i) => (
          <FeedItem
            key={`${item.type}-${item.id}`}
            item={item}
            {...sharedProps}
            positionInUnit={subunitName ? positionInUnit : item.position}
            positionInSubunit={subunitName ? item.position : null}
            lazyLoad={i >= preloadImageCount}
          />
        ))}
      </>
    );
  }

  return (
    <section
      className={
        classNames(className, 'feed') + `
      ${isScrollableMobile ? 'feed__scrollable-mobile' : '' }
      ${columns === 6 ? 'feed__6-col' : '' }
      ${columns === 3 ? 'feed__3-col' : '' }
      `}
    >
      {heading && <FeedHeading heading={heading} href={href} eventInfo={eventInfo} />}
      <ul className='feed__items list-unstyled'>
        {feed}
      </ul>
      {href && (
        <TrackableLink
          className="see-all-btn button--tasty button--tasty-secondary extra-bold xs-flex xs-mt3 xs-flex-justify-center"
          linkTracking={{...eventInfo, item_type: 'button'}}
          url={href}
        >
          See all
        </TrackableLink>
      )}
    </section>
  );
}

Feed.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  href: PropTypes.string,
  items: PropTypes.array,
  mediaSizes: PropTypes.string,
  preloadImageCount: PropTypes.number,
  subunitName: PropTypes.string,
  unitName: PropTypes.string,
  unitType: PropTypes.string,
};

export default Feed;
