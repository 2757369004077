/**
 * Most feed items are part of a 'package' subunit (e.g. 'trending' unit on a compilations page)
 *  but not necessarily (e.g. feed items in the main search feed, where there is no subunit).
 *  If subunitName is not provided, then can assume it is not meant to have a subunit.
 */
export const clientTrackingFeedItemUnitLayer = ({
  dataSourceAlgorithm,
  dataSourceAlgorithmVersion,
  dataSourceName,
  item,
  subunitName,
  unitName,
  unitType,
  positionInUnit,
  positionInSubunit
}) => {
  return {
    data_source_algorithm: dataSourceAlgorithm || [],
    data_source_algorithm_version: dataSourceAlgorithmVersion || [],
    data_source_name: dataSourceName || '',
    item_name: `${item.id}`,
    item_type: 'card',
    position_in_subunit: subunitName ? positionInSubunit : null,
    position_in_unit: positionInUnit,
    subunit_name: subunitName || '',
    subunit_type: subunitName ? 'package' : '',
    target_content_id: `${item.id}`,
    target_content_type: item.type === 'article' ? 'buzz' : item.type,
    unit_name: unitName,
    unit_type: unitType,
  };
};
