import { AnalyticsContext } from '@/context';
import tracking from '@/services/tracking';
import { attachClientImpressionHandler } from '@/services/tracking/client-event-tracking';
import { CdnImage, withErrorBoundary } from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';
import ClientLink from '../ClientLink';
import PropTypes from 'prop-types';
import React, { memo, useContext, useEffect, useRef } from 'react';
import SvgIcon from '../svg/SvgIcon';
import { clientTrackingFeedItemUnitLayer } from './helpers';

const FeedItem = ({
  dataSourceAlgorithm,
  dataSourceAlgorithmVersion,
  dataSourceName,
  columns = 2,
  hasLargeLabelDesktop = false,
  hasLargeLabelMobile = false,
  hasRightText = false,
  hasSmallText = false,
  hasWideImage = false,
  isPlaceholder = false,
  isScrollableMobile = false,
  item,
  lazyLoad = true,
  subunitName,
  unitName,
  unitType,
  positionInUnit = 0,
  positionInSubunit = null,
  displayBrand,
}) => {
  const elRef = useRef(null);
  let mediaSizes = '(min-width: 52rem) 600px, 300px';
  if (hasWideImage && !isScrollableMobile) {
    // for wide images that can take up the full width on smaller devices, make larger
    mediaSizes = '600px';
  }

  const pageInfo = useContext(AnalyticsContext);

  const eventInfo = {
    ...clientTrackingFeedItemUnitLayer({
      dataSourceAlgorithm,
      dataSourceAlgorithmVersion,
      dataSourceName,
      item,
      positionInUnit,
      positionInSubunit,
      subunitName,
      unitName,
      unitType
    }),
  };

  const isCommunity = item.credits ? item.credits.some(credit => credit.type === 'community') : false;
  const itemName = item.name || item.title || '';

  const onFeedItemClick = () => {
    tracking.trackInternalLink({ eventInfo, pageInfo });
  };

  useEffect(() => {
    if (elRef && elRef.current) {
      attachClientImpressionHandler(
        elRef.current,
        pageInfo,
        clientTrackingFeedItemUnitLayer({
          dataSourceAlgorithm,
          dataSourceAlgorithmVersion,
          dataSourceName,
          item,
          positionInUnit,
          positionInSubunit,
          subunitName,
          unitName,
          unitType
        })
      );
    }
  }, [elRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const under30Min = item.times ? item.times.total_time.minutes <= 30 && item.times.total_time.minutes > 0 : false;
  const displayCookTimeLabel = under30Min && (subunitName === 'related_recipes' || subunitName === 'trending_recipes') && pageInfo.page !== 'home';

  const linkContents = (
    <>
      <div className="feed-item__img-wrapper">
        <CdnImage
          alt={item.thumbnail_alt_text ? item.thumbnail_alt_text : ''}
          className="feed-item__img"
          lazy={lazyLoad}
          src={item.thumb_standard}
          srcSet={`${item.thumb_standard} 300w, ${item.thumb_big} 600w`}
          sizes={mediaSizes}
        />
        {isCommunity &&
          <span className={`
            ugc-label
            ${hasLargeLabelDesktop ? 'ugc-label__large--desktop' : ''}
            ${hasLargeLabelMobile ? 'ugc-label__large--mobile' : ''}
          `}>
            Community
          </span>}
      </div>
      <div className='xs-flex xs-flex-column feed-item__text-wrapper'>
        <div className={`feed-item__title ${hasSmallText ? 'feed-item__title-small' : ''}`}>{itemName}</div>
        {item.brand_id && displayBrand && <div className="feed-item__brand">Presented by {item.brand_name}</div>}
      </div>
      { displayCookTimeLabel && <div className="feed-item__cooktime-label xs-flex xs-flex-nowrap xs-flex-align-center">
        <SvgIcon aria-hidden="true" height="14" id="clock-icon" width="14" />
        <p className="xs-text-6 extra-bold">Under 30 min</p></div>
      }
    </>
  );

  const link = (
    <li className={`
      feed-item
      ${isPlaceholder ? 'feed-item__placeholder' : ''}
      ${isScrollableMobile ? 'feed-item__scrollable-mobile' : ''}
      ${columns === 1 ? 'feed-item__single-col' : ''}
      ${columns === 3 ? 'feed-item__3-col' : ''}
      ${columns === 4 ? 'feed-item__4-col' : ''}
      ${columns === 6 ? 'feed-item__6-col' : ''}
      ${hasWideImage ? 'feed-item__wide-image' : ''}
      ${hasRightText ? 'feed-item__right-text' : ''}
    `}
    >
      {isPlaceholder ? linkContents :
        <a
          href={`/${item.type}/${item.slug}`}
          onClick={onFeedItemClick}
          ref={elRef}
        >
          {linkContents}
        </a>}
    </li>
  );

  if (item.type === 'article' || isPlaceholder) {
    return link;
  }

  return (
    <ClientLink
      as={`/${item.type}/${item.slug}`}
      href={`/${item.type}/[slug]`}
    >
      {link}
    </ClientLink>
  );
};

FeedItem.propTypes = {
  item: PropTypes.object,
  lazyLoad: PropTypes.bool,
  mediaSizes: PropTypes.string,
  subunitName: PropTypes.string,
  unitName: PropTypes.string,
  unitType: PropTypes.string,
  positionInUnit: PropTypes.number,
};

export default memo(withErrorBoundary(FeedItem, {
  onError: captureException
}));
